/* eslint-disable react/no-danger */
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStores } from 'services/useStore';

export interface ILiqPayButton {
  id: string;
  width?: number;
  amount: number;
  description: string;
}

const LiqPayButton: React.FC<ILiqPayButton> = observer(({ width, id, amount, description }) => {
  const { api } = useStores();
  const [html, setHTML] = useState('');
  useEffect(() => {
    if (!id || !amount || !description) return;
    api.liqSubscribePayButton({ id, amount, description }).then((content) => {
      let htmlContent = content.replaceAll('<sdk-button', '<button class="liqPayButton"');
      htmlContent = htmlContent.replaceAll('></sdk-button>', '/>');
      //htmlContent = htmlContent.replaceAll('<form', '<form target="_blank"');
      setHTML(htmlContent);
    });
  }, [id, amount, description]);
  return (
    <div
      className='liqPayButton-container'
      style={{ width }}
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  );
});

export default LiqPayButton;
