/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Alert, Button, Col, Container, FloatingLabel, Form, Row, Stack } from 'react-bootstrap';
import { useStores } from 'services/useStore';
import { useTranslation } from 'react-i18next';
import { TSuccessResponse } from 'services/Api';
import { observer } from 'mobx-react';
import {
  ProjectPayTypes,
  ProjectStatuses,
  ProjectTypes,
  TProjectLARP,
  TProjectSettings,
} from 'stores/ProjectsStore';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from 'components/App/App';
import ImageList from 'components/Heplers/ImageList';
import {
  CashCoin,
  CheckCircleFill,
  ExclamationTriangleFill,
  Plus,
  Trash,
} from 'react-bootstrap-icons';
import { TUserRole, UserStatus } from 'stores/SessionStore';
import UserFind from 'components/Heplers/UserFind';
import UserShow from 'components/Heplers/UserShow';
import AITextButton from 'components/Buttons/AITextButton';
import moment from 'moment';
import DatePicker from 'components/Heplers/DatePicker';
import LinkList from 'components/Heplers/LinkList';
import InfoButton from 'components/Heplers/InfoButton';
import PricingDialog from 'components/Dialogs/PricingDialog';
import LinkButton from 'components/Heplers/LinkButton';
import SubscribeDialog from 'components/Dialogs/SubscribeDialog';
//import styles from './style.module.scss';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let timer: any;

export type TCheckResult = {
  elements?: { [key: string]: boolean | string | number };
  error?: string;
};

type TProjectPayInfo = {
  project_pay_time: string;
  project_pay_status: ProjectPayTypes;
};

const ProjectEdit: React.FC = observer(() => {
  const pId = useParams().params || 0;
  const { t } = useTranslation();
  const { api, projectStore, sessionStore } = useStores();
  api.noLogin();
  const [projectId, setProjectId] = useState(+pId);
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('');
  const [successText, setSuccessText] = useState('');
  const [imgList, setImgList] = useState([] as string[]);
  const [roles, setRoles] = useState<TUserRole[]>();
  const [newRoles, setNewRoles] = useState(0);
  const [userId, setUserId] = useState(0);
  const [projectStart, setProjectStart] = useState<moment.Moment>();
  const [projectEnd, setProjectEnd] = useState<moment.Moment>();
  const [open, setOpen] = useState<boolean>();
  const [freQuenta, setFreQuenta] = useState<boolean>();
  const [inviteOnly, setInviteOnly] = useState<boolean>();
  const [requestOpen, setRequestOpen] = useState<boolean>();
  const [isAdmin, setiIsAdmin] = useState(false);
  const [useImageAI, setuseImageAI] = useState(false);
  const [canSave, setCanSave] = useState<string>('');
  const [useAI, setUseAI] = useState(false);
  const [useGSH, setUseGSH] = useState(false);
  const [gsh, setGSH] = useState<string>('');
  const [openAiKey, setOpenAiKey] = useState<string>();
  const [pricing, setPricing] = useState<string | false>();
  const [defaultImagePrompt, setDefaultImagePrompt] = useState<string>();
  const [lang, setLang] = useState<string>('ua');
  const [settings, setSettings] = useState<TProjectSettings>();
  const [imageStyle, setImageStyle] = useState<string>('');
  const [stylesList, setStylesList] = useState<string[]>([]);
  const [checkResult, setCheckResult] = useState<TCheckResult>();
  const [random, setRandom] = useState(0);
  const [checkOferta, setCheckOferta] = useState(false);
  const [showSubscribeDialog, setShowSubscribeDialog] = useState(false);
  const [projectPayData, setProjectPayData] = useState<TProjectPayInfo>();
  //const [showPromocodeWindow, setShowPromocodeWindow] = useState(false);
  //const [promocodeId, setPromocodeId] = useState(0);
  //const [promocodeName, setPromocodeName] = useState<string>();

  const onTitle = useCallback((value: string) => {
    setTitle(value);
  }, []);

  const setStage = (newStage: string) => {
    setStatus(newStage);
    api.projectCheck(newStage, projectId).then((result: TCheckResult) => {
      console.log(newStage, result);
      setCheckResult(result);
    });
  };

  const update = () => {
    if (projectId) {
      api.getProjects(projectId).then((data) => {
        const project = data as any;
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const { project_pay_time, project_pay_status } = project;
        setName(project.project_name);
        setTitle(project.project_title);
        setDescription(project.project_description);
        setStatus(project.project_status);
        setRoles(
          project.user_role.filter((role: TUserRole) => {
            return role.status === UserStatus.Manager || role.status === UserStatus.Owner;
          })
        );
        setUserId(project.user_id);
        setPricing(project.project_pricing);
        setOpen(project.project_open > 0);
        setRequestOpen(project.project_open === 2);
        setProjectStart(moment(project.project_start));
        setProjectEnd(moment(project.project_end));
        setProjectPayData({
          project_pay_time,
          project_pay_status,
        });
        const settingsData: TProjectSettings =
          typeof project.project_settings === 'string'
            ? JSON.parse(project.project_settings.replaceAll("'", '"') || '{}')
            : project.project_settings || {};
        setSettings(settingsData);
        setuseImageAI(settingsData.useImageAI || false);
        setFreQuenta(settingsData.freQuenta || false);
        setInviteOnly(settingsData.inviteOnly || false);
        setUseAI(settingsData.useAI || false);
        setUseGSH(!!settingsData.gsh);
        setGSH(settingsData.gsh || '');
        setOpenAiKey(settingsData.openAiKey || '');
        setLang(settingsData.lang || 'ua');
        setDefaultImagePrompt(settingsData.defaultImagePrompt || '');
        setImageStyle(settingsData.imageStyle || 'none');
        const isOwner =
          project.user_id === sessionStore.session.user_id ||
          sessionStore.session.user_status === UserStatus.Admin;
        setiIsAdmin(isOwner);
        projectStore.update(project);
        if (project.project_images) setImgList(project.project_images);
        else setImgList([]);
        setStage(project.project_status);
        if (project_pay_status === ProjectPayTypes.NEW) {
          setShowSubscribeDialog(true);
          setCanSave(t('projectForm.subscribeAlert'));
        } else if (project_pay_status === ProjectPayTypes.STOP) {
          setCanSave(t('projectForm.stopAlert'));
        } else if (project_pay_status === ProjectPayTypes.END) {
          setCanSave(t('projectForm.endAlert'));
        }
      });
    }
  };

  const updateImages = useCallback(() => {
    api.getProjects(projectId).then((data) => {
      const project = data as TProjectLARP;
      if (project.project_images) setImgList(project.project_images);
      else setImgList([]);
    });
  }, []);

  const setupRequestOpen = (newValue: boolean) => {
    setRequestOpen(newValue);
    if (!open) setOpen(true);
  };

  const selectPricing = (priceName: string | false) => {
    console.log(priceName);
    setPricing(priceName);
    if (priceName === 'free') {
      setuseImageAI(false);
    }
    if (priceName === false) {
      setCanSave('projectForm.selectPriceAlert');
      return;
    }
    setCanSave('');
    /// Раскоментировать на релизе
    /*if (sessionStore.session.user_balance < api.settings.pricing[priceName]) {
      setCanSave('projectForm.priceAlert');
    } else {
      setCanSave('');
    }*/
  };

  const checkOfertaStatus = () => {
    if (projectId || pricing === false) return;
    if (!checkOferta) {
      setCanSave('projectForm.ofertaAlert');
      return;
    }
    setCanSave('');
  };

  const showPricingWindow = () => {
    api.togglePricingGenerateDialog(selectPricing, pricing);
  };

  useEffect(() => {
    checkOfertaStatus();
  }, [checkOferta]);

  useEffect(() => {
    update();
    if (!projectId) api.togglePricingGenerateDialog(selectPricing);
    setTimeout(() => {
      api.getStylesForGenerateImage().then((list) => {
        if (Array.isArray(list)) setStylesList(list);
      });
    }, 100);
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSettings({
      ...settings,
      useImageAI,
      useAI,
      openAiKey,
      lang,
      defaultImagePrompt,
      imageStyle,
      freQuenta,
      inviteOnly,
      gsh,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    useImageAI,
    useAI,
    openAiKey,
    lang,
    defaultImagePrompt,
    imageStyle,
    freQuenta,
    inviteOnly,
    useGSH,
  ]);

  const deleteItem = () => {
    api
      .deleteItem('project', projectId, t('projectForm.deletePrompt'))
      .then((result) => {
        projectStore.remove(projectId);
        window.location.href = ROUTES.projects;
      })
      .catch((e) => {});
  };

  const isOwner = () => sessionStore.role() === UserStatus.Owner;

  const updateProject = (event: React.FormEvent) => {
    const form = event.currentTarget as HTMLFormElement;
    if (!projectId && !checkOferta) {
      event.preventDefault();
      event.stopPropagation();
      checkOfertaStatus();
      return false;
    }
    if (form.checkValidity()) {
      setValidated(true);
      const data: TProjectLARP = {
        project_name: name.trim(),
        project_title: title.trim(),
        project_description: description.trim(),
        project_status: status,
        project_type: ProjectTypes.LARP,
        project_settings: JSON.stringify(settings),
        user_id: userId,
        // eslint-disable-next-line no-nested-ternary
        project_open: open ? (requestOpen ? 2 : 1) : 0,
        project_start: projectStart?.format('YYYY-MM-DD') || '',
        project_end: projectEnd?.format('YYYY-MM-DD') || '',
        project_pricing: pricing || 'full',
      };
      if (projectId) data.project_id = projectId;
      api.setProject(data).then(async (res: TSuccessResponse) => {
        const { errors, success } = res;
        if (!errors) {
          if (projectId) {
            api.getProjects(projectId).then((projectData) => {
              let pd: TProjectLARP = projectData as TProjectLARP;
              if (pd.project_images?.length) {
                const [imgId, imgMime] = pd.project_images[0].split('.');
                pd = { img_id: +imgId, img_mime: imgMime, ...pd };
              }
              projectStore.update(pd);
              setRandom(Math.random());
            });
          } else if (success) {
            projectStore.add({ project_id: +success, ...data });
            setProjectId(+success);
            setRandom(Math.random());
            setTimeout(() => {
              setShowSubscribeDialog(true);
            }, 1000);
          }
          await api.checkSession();
          console.log('OK');
          setSuccessText(projectId ? 'projectForm.editSuccess' : 'projectForm.createSuccess');
          setStage(status);
          timer = setTimeout(() => {
            setSuccessText('');
          }, 5000);
        }
      });
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      event.stopPropagation();
    }

    return false;
  };

  const showProjectCheckValue = (localeKey: string, value: number | string | boolean) => {
    let valueView: ReactElement = <></>;
    if (typeof value === 'boolean')
      valueView = value ? (
        <CheckCircleFill color='green' size={16} />
      ) : (
        <ExclamationTriangleFill color='orange' size={16} />
      );
    else if (typeof value === 'number') valueView = <b>{value}</b>;
    else if (value.indexOf('/') > 0)
      valueView = <b>{value.replace('/', t('projectForm.statusCheck.from'))}</b>;
    else valueView = <b>{value}</b>;
    return (
      <div key={localeKey} className='checkElement'>
        {valueView} <span>{t(localeKey)}</span>
      </div>
    );
  };
  const successAlert = successText ? <Alert variant='success'>{t(successText)}</Alert> : '';
  const roleBlock =
    isAdmin && roles?.length ? (
      <Row className='justify-content-md-center' style={{ marginTop: 24 }}>
        <Col xs lg={10}>
          <Row className='justify-content-md-center'>
            <Col>
              <h3>{t('projectForm.managers')}</h3>
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col lg={11}>
              <UserFind action={setNewRoles} />
              {newRoles ? <UserShow userId={newRoles} isSearch linkInDialog hideEmpty /> : ''}
            </Col>
            <Col lg={1}>
              <Button
                variant='success'
                size='lg'
                onClick={() => {
                  if (newRoles && projectId) {
                    api
                      .updateRole('add', newRoles, projectId, 'manager')
                      .then((result) => {
                        setNewRoles(0);
                        update();
                      })
                      .catch((e) => {});
                  }
                }}
              >
                <Plus fontSize={38} />
              </Button>
            </Col>
          </Row>
          {roles?.map((role: TUserRole) => (
            <Row
              key={role.user_id}
              className='justify-content-md-center'
              style={{
                border: '1px solid #00000022',
                borderRadius: 3,
                padding: 14,
                marginTop: 14,
                backgroundColor: '#fff',
              }}
            >
              <Col>
                <Row>
                  <Col>
                    <UserShow userId={role.user_id} allUsers />
                  </Col>
                  <Col style={{ display: 'flex', alignItems: 'center' }}>{role.status}</Col>
                </Row>
              </Col>

              {role.user_id !== sessionStore.session.user_id ? (
                <Col style={{ display: 'flex', justifyContent: 'flex-end' }} xs={1}>
                  <Button
                    variant='danger'
                    size='sm'
                    style={{ height: 50, width: 50, fontSize: 20 }}
                    onClick={() => {
                      if (role.user_id && projectId)
                        api
                          .updateRole(
                            'delete',
                            role.user_id,
                            projectId,
                            '',
                            t('global.deletePrompt')
                          )
                          .then((result) => update())
                          .catch((e) => {});
                    }}
                  >
                    <Trash />
                  </Button>
                </Col>
              ) : (
                ''
              )}
            </Row>
          ))}
        </Col>
      </Row>
    ) : (
      ''
    );
  return (
    <Container fluid className='verticalPadding greenForm'>
      <Form onSubmit={updateProject} action='#' validated={validated}>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <Stack direction='horizontal' gap={2}>
              <div>
                <h2>
                  {t(projectId ? 'projectForm.edit' : 'projectForm.create')}
                  {projectId ? <span className='text-muted'> #{projectId}</span> : ''}
                </h2>
              </div>
              {pricing || pricing === false ? (
                <div className='ms-auto'>
                  <Button variant='info' onClick={showPricingWindow}>
                    <CashCoin fontSize={20} />
                    &nbsp;&nbsp;{t(`pricing.pricing`)} <b>{t(`pricing.${pricing}.name`)}</b>
                  </Button>
                </div>
              ) : (
                ''
              )}
            </Stack>

            <FloatingLabel controlId='name' label={t('projectForm.name')} className='mb-3'>
              <Form.Control
                type='text'
                required
                size='lg'
                value={name || ''}
                onChange={(e) => setName(e.target.value)}
                placeholder={t('projectForm.name')}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId='title'
              label={title ? '' : t('projectForm.title')}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                required
                rows={5}
                size='lg'
                value={title || ''}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={t('projectForm.title')}
                style={{ height: '100px' }}
              />
              <AITextButton handler={onTitle} text={title} />
            </FloatingLabel>
            <FloatingLabel
              controlId='description'
              label={description ? '' : t('projectForm.description')}
              className='mb-3'
            >
              <Form.Control
                as='textarea'
                required
                rows={5}
                size='lg'
                value={description || ''}
                onChange={(e) => setDescription(e.target.value)}
                placeholder={t('projectForm.description')}
                style={{ height: '100px' }}
              />
            </FloatingLabel>
            <FloatingLabel controlId='lang' label={t('projectForm.lang')} className='mb-3'>
              <Form.Select
                aria-label={t('projectForm.lang')}
                required
                size='lg'
                value={lang}
                onChange={(e) => setLang(e.currentTarget.value)}
              >
                <option value='ua'>Українська</option>
                <option value='en'>English</option>
              </Form.Select>
            </FloatingLabel>
            {!projectId || projectStart ? (
              <DatePicker
                title={t('projectForm.start')}
                date={projectStart || false}
                max={projectEnd}
                callback={setProjectStart}
              />
            ) : (
              ''
            )}
            {!projectId || projectStart ? (
              <DatePicker
                title={t('projectForm.end')}
                date={projectEnd || false}
                min={projectStart}
                callback={setProjectEnd}
              />
            ) : (
              ''
            )}
            {successAlert}
          </Col>
        </Row>
        <Row className='justify-content-md-center'>
          <Col xs lg={10}>
            <Alert variant='success'>{t('profileForm.publicNotion')}</Alert>
          </Col>
        </Row>
        {projectId ? (
          ''
        ) : (
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <Form.Check
                type='switch'
                name='oferta'
                style={{ marginBottom: 20 }}
                label={<LinkButton label='projectForm.oferta' text='oferta' />}
                checked={checkOferta || false}
                onChange={(e: any) => setCheckOferta(e.target.checked)}
              />
            </Col>
          </Row>
        )}
        {/*!projectId && pricing && pricing !== 'free' ? (
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <Button onClick={() => setShowPromocodeWindow(true)} variant='success'>
                <Balloon /> {t('projectForm.havePromocode')}
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )*/}
        {canSave ? (
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <Alert
                variant='danger'
                className='d-flex'
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>{t(canSave)}</div>
                {projectId ? (
                  <Button variant='success' onClick={() => setShowSubscribeDialog(true)}>
                    {t('projectForm.buttonSubscr')}
                  </Button>
                ) : (
                  ''
                )}
              </Alert>
            </Col>
          </Row>
        ) : (
          ''
        )}
        {!canSave &&
        projectId &&
        pricing !== 'free' &&
        (projectPayData?.project_pay_status === ProjectPayTypes.STOP ||
          projectPayData?.project_pay_status === ProjectPayTypes.NEW) ? (
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <Alert
                variant='danger'
                className='d-flex'
                style={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div>{t('projectForm.paymentFrezeAlert')}</div>
                {isOwner() ? (
                  <Button variant='success' onClick={() => setShowSubscribeDialog(true)}>
                    {t('projectForm.buttonSubscr')}
                  </Button>
                ) : (
                  ''
                )}
              </Alert>
            </Col>
          </Row>
        ) : (
          ''
        )}
        {projectId &&
        pricing !== 'free' &&
        projectPayData?.project_pay_status === ProjectPayTypes.END ? (
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <Alert variant='danger'>
                <Row>
                  <Col xs lg={9}>
                    {t('projectForm.paymentEndAlert')}
                  </Col>
                  {isOwner() ? (
                    <Col xs lg={3}>
                      <Button onClick={() => setShowSubscribeDialog(true)}>
                        {t('projectForm.buttonSubscr')}
                      </Button>
                    </Col>
                  ) : (
                    ''
                  )}
                </Row>
              </Alert>
            </Col>
          </Row>
        ) : (
          ''
        )}

        <Row className='justify-content-md-center' style={{ marginTop: 24 }}>
          <Col lg={4}>
            <Row className='justify-content-md-center'>
              <Col xs={6}>
                <div className='d-grid gap-2'>
                  <Button
                    variant='primary'
                    disabled={!!canSave}
                    className='col-12'
                    size='lg'
                    type='submit'
                  >
                    {t(projectId ? 'global.save' : 'global.create')}
                  </Button>
                </div>
              </Col>
              <Col xs={6}>
                <div className='d-grid gap-2'>
                  <Link to={ROUTES.projects}>
                    <Button variant='secondary' className='col-12' size='lg'>
                      {t('global.back')}
                    </Button>
                  </Link>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      {projectId ? (
        <>
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <h2>{t('projectForm.status')}</h2>
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col xs lg={9}>
              <Form.Select
                required
                size='lg'
                value={status}
                onChange={(e) => setStage(e.currentTarget.value)}
              >
                {Object.values(ProjectStatuses)
                  .filter((value) => typeof value === 'string')
                  .map((stage: string) => (
                    <option key={stage} value={stage}>
                      {t('projectForm.statuses.' + stage)}
                    </option>
                  ))}
              </Form.Select>
            </Col>
            <Col xs lg={1}>
              <InfoButton text='projectStatus' />
            </Col>
          </Row>
          {status ? (
            <Row className='justify-content-md-center mb-3 mt-2'>
              <Col xs lg={10}>
                <Alert show variant='info'>
                  {t('projectForm.statusDescription.' + status)}
                </Alert>
              </Col>
            </Row>
          ) : (
            ''
          )}
          {checkResult?.elements ? (
            <Row className='justify-content-md-center mb-3 projectCheck'>
              <Col xs lg={10}>
                <Alert show variant='success'>
                  <Alert.Heading>{t('projectForm.statusCheck.prefix')}</Alert.Heading>
                  <hr />
                  {Object.entries(checkResult.elements).map(([k, v]) =>
                    showProjectCheckValue(k, v)
                  )}
                </Alert>
              </Col>
            </Row>
          ) : (
            ''
          )}
          {/*<Row
            className='justify-content-md-center'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <Col xs lg={5}>
              <Form.Check
                type='switch'
                key='useGSH'
                name='type'
                style={{ marginBottom: 20 }}
                label={t('projectForm.useGSH')}
                checked={useGSH || false}
                onChange={(e: any) => setUseGSH(e.target.checked)}
              />
            </Col>

            {useGSH ? (
              <Col xs lg={5}>
                <Row>
                  <Col xs lg={10}>
                    <FloatingLabel controlId='openAIKey' label={t('projectForm.GSHid')}>
                      <Form.Control
                        type='text'
                        required
                        size='lg'
                        value={gsh || ''}
                        onChange={(e) => setGSH(e.target.value)}
                        placeholder={t('projectForm.openAIKey')}
                      />
                    </FloatingLabel>
                  </Col>
                  <Col xs lg={1}>
                    <InfoButton text='gsh' />
                  </Col>
                </Row>
              </Col>
            ) : (
              <Col xs lg={5}>
                {' '}
              </Col>
            )}
          </Row>*/}

          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <Form.Check
                type='switch'
                key='opened'
                name='type'
                style={{ marginBottom: 20 }}
                label={t('projectForm.inviteOnly')}
                checked={inviteOnly || false}
                onChange={(e: any) => setInviteOnly(e.target.checked)}
              />
            </Col>
          </Row>
          {!inviteOnly ? (
            <>
              <Row className='justify-content-md-center'>
                <Col xs lg={10}>
                  <Form.Check
                    type='switch'
                    key='opened'
                    name='type'
                    style={{ marginBottom: 20 }}
                    label={t('projectForm.openProject')}
                    checked={open || false}
                    onChange={(e: any) => setOpen(e.target.checked)}
                  />
                </Col>
              </Row>
              {open ? (
                <Row className='justify-content-md-center'>
                  <Col xs lg={10}>
                    <Form.Check
                      type='switch'
                      key='openrequest'
                      name='type'
                      style={{ marginBottom: 20 }}
                      label={t('projectForm.openRequest')}
                      checked={requestOpen || false}
                      onChange={(e: any) => setupRequestOpen(e.target.checked)}
                    />
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </>
          ) : (
            ''
          )}
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <Form.Check
                type='switch'
                key='opened'
                name='type'
                style={{ marginBottom: 20 }}
                label={t('projectForm.freQuenta')}
                checked={freQuenta || false}
                onChange={(e: any) => setFreQuenta(e.target.checked)}
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <Row className='justify-content-md-center'>
                {pricing !== 'free' ? (
                  <Col xs lg={6} className='mb-3'>
                    <Row className='justify-content-md-center'>
                      <Col xs lg={12}>
                        <h2>{t('projectForm.useAI')}</h2>
                      </Col>
                    </Row>
                    <Row className='justify-content-md-center'>
                      <Col xs lg={12}>
                        <Form.Check
                          type='switch'
                          key='openrequest'
                          name='type'
                          style={{ marginBottom: 20 }}
                          label={t('projectForm.openImageAI')}
                          checked={useImageAI || false}
                          onChange={(e: any) => setuseImageAI(e.target.checked)}
                        />
                      </Col>
                    </Row>
                    {useImageAI ? (
                      <Row className='justify-content-md-center'>
                        <Col xs lg={12} className='mb-3'>
                          <Row>
                            <Col xs lg={10}>
                              <FloatingLabel controlId='type' label={t('generation.style')}>
                                <Form.Select
                                  aria-label={t('generation.style')}
                                  required
                                  size='lg'
                                  value={imageStyle}
                                  onChange={(e) => setImageStyle(e.currentTarget.value)}
                                >
                                  <option key='none' value='none'>
                                    {t('generation.imageStyles.none')}
                                  </option>
                                  {stylesList.map((item) => (
                                    <option key={item} value={item}>
                                      {t(`generation.imageStyles.${item}`)}
                                    </option>
                                  ))}
                                </Form.Select>
                              </FloatingLabel>
                            </Col>
                          </Row>
                          <Row>
                            <Col xs lg={10}>
                              <FloatingLabel
                                controlId='defauldImagePrompt'
                                label={t('projectForm.defauldImagePrompt')}
                              >
                                <Form.Control
                                  type='text'
                                  required
                                  size='lg'
                                  value={defaultImagePrompt || ''}
                                  onChange={(e) => setDefaultImagePrompt(e.target.value)}
                                  placeholder={t('projectForm.defauldImagePrompt')}
                                />
                              </FloatingLabel>
                            </Col>
                            <Col xs lg={2}>
                              <InfoButton text='defaultImagePrompt' />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      ''
                    )}

                    <Row
                      className='justify-content-md-center'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Col xs lg={12}>
                        <Form.Check
                          type='switch'
                          key='useAI'
                          name='type'
                          style={{ marginBottom: 20 }}
                          label={t('projectForm.openAI')}
                          checked={useAI || false}
                          onChange={(e: any) => setUseAI(e.target.checked)}
                        />
                      </Col>
                    </Row>
                    {useAI ? (
                      <Row>
                        <Col xs lg={10}>
                          <FloatingLabel controlId='openAIKey' label={t('projectForm.openAIKey')}>
                            <Form.Control
                              type='text'
                              required
                              size='lg'
                              value={openAiKey || ''}
                              onChange={(e) => setOpenAiKey(e.target.value)}
                              placeholder={t('projectForm.openAIKey')}
                            />
                          </FloatingLabel>
                        </Col>
                        <Col xs lg={1}>
                          <InfoButton text='openAi' />
                        </Col>
                        <Link
                          to={
                            api.settings.url.server +
                            'aiRobot/fullinfo/' +
                            projectId +
                            '?x=' +
                            sessionStore.session.user_session
                          }
                          target='_blank'
                        >
                          {t('projectForm.openAIInfo')}
                        </Link>
                      </Row>
                    ) : (
                      ' '
                    )}
                  </Col>
                ) : (
                  ''
                )}
                <Col xs lg={6}>
                  {roleBlock}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <h2>{t('projectForm.images')}</h2>
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <ImageList
                id={projectId}
                type='project'
                genImgText={title}
                imgList={imgList}
                updateImagesHandle={updateImages}
                uploadUrl={'uploadProjectImage/' + projectId}
                random={random}
              />
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col xs lg={10}>
              <h2>{t('projectForm.linkTexts')}</h2>
            </Col>
          </Row>
          <Row className='justify-content-md-center mb-3'>
            <Col lg={10}>
              <LinkList
                type='project'
                subType='text'
                id={projectId}
                storeName='projectLinks'
                title={t('text.mainTitle')}
                muteLoadFromAPI={false}
              />
            </Col>
          </Row>
          {isOwner() || isAdmin ? (
            <>
              {pricing !== 'free' &&
              projectPayData?.project_pay_status === ProjectPayTypes.PAYED ? (
                <Row className='justify-content-md-center'>
                  <Col lg={10} className='text-center'>
                    <Button variant='danger' size='sm' onClick={() => {}}>
                      <Trash /> {t('projectForm.buttonStopSubscr')}
                    </Button>
                  </Col>
                </Row>
              ) : (
                ''
              )}
              <Row className='justify-content-md-center mt-3'>
                <Col lg={10} className='text-center'>
                  <Button variant='danger' size='sm' onClick={() => deleteItem()}>
                    <Trash /> {t('projectForm.delete')}
                  </Button>
                </Col>
              </Row>
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
      <SubscribeDialog
        projectId={projectId}
        show={showSubscribeDialog}
        hideCallback={() => setShowSubscribeDialog(false)}
      />
      <PricingDialog />
    </Container>
  );
});

export default ProjectEdit;
