/* eslint-disable class-methods-use-this */
import { TGraphData } from 'components/LinksGraph/LinksGraph';
import { TSearchResult } from 'components/Search/SearchResult';
import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';
import LinkStore, { TLink, TLinkData, TPersonLink } from 'stores/LinkStore';
import { TLocation, TLocationTree } from 'stores/LocationsStore';
import { TPerson } from 'stores/PersonsStore';
import { ProjectPayTypes, TProject, TProjectSettings } from 'stores/ProjectsStore';
import { TQuest } from 'stores/QuestsStore';
import { TRelationLink } from 'stores/RelationsStore';
import { TRequest } from 'stores/RequestsStore';
import { RootStore } from 'stores/RootStore';
import { TBlackList, TProfile, TSession, UserStatus } from 'stores/SessionStore';
import { TNews } from 'stores/NewsStore';
import { TStatus } from 'stores/StatusesStore';
import { TStuff } from 'stores/StuffsStore';
import { TTask } from 'stores/TasksStore';
import { TTextFolder } from 'stores/TextFoldersStore';
import { TText } from 'stores/TextsStore';
import { TUser } from 'stores/UsersStore';
import { TCreatePersonReturn } from 'stores/GeneratedPersonsStore';
import { TCreateLocationReturn } from 'stores/GeneratedLocationsStore';
import { TCreateQuestReturn } from 'stores/GeneratedQuestsStore';
import { TBilling } from 'stores/BillingsStore';
import { TStuffFolder } from 'stores/StuffFoldersStore';
// eslint-disable-next-line import/no-extraneous-dependencies
import simpleParallax from 'simple-parallax-js';
import { ROUTES } from 'components/App/App';
import { TCheckResult } from 'components/Projects/ProjectEditLARP';
import { TCommandInfo, TIdInfo } from 'components/Projects/ProjectAILARP';
import i18n from '../locales/i18n';
import { TUseTelegram, useTelegram } from './useTelegram';

export type TRegisterData = {
  email: string | false;
  password: string | false;
  name: string | false;
  telegramId?: number;
  fbId?: number;
};

export type TSessionRequest = {
  errors?: { user_email?: string };
  session?: TSession;
};

export type TProfileRequests = {
  request_id?: number;
  person_id: number;
  person_name: string;
  request_name: string;
};

export type TProfileRequest = {
  errors?: { user_email?: string };
  profile?: TProfile;
  requests?: TProfileRequests[];
  persons?: TProfileRequests[];
  blacklist?: TBlackList[];
};

export type TSuccessResponseWihNews = {
  errors?: { [key: string]: string };
  success?: { news_id: number; [key: string]: number };
};

export type TSuccessResponse = {
  errors?: { [key: string]: string };
  success?: boolean | number;
};

type TSimpleUsers = {
  players: TUser[];
  managers: TUser[];
};

export type TUserSearch = {
  id: number;
  name: string;
  image: string;
};

export type TObjectLink = {
  type: string;
  subType?: string;
  toId: number;
  fromId: number;
  description?: string;
  nameFrom?: string;
  nameTo?: string;
  status?: string;
  name?: string;
};

export type TUserToUser = {
  source: number;
  target: number;
  type: string;
};

export type TCreateNewLocationSetup = {
  project_id?: number;
  notice?: string;
  stage?: number;
  generatedText?: string;
  count?: number;
  aiImgId?: number;
  imageAutoAdd?: boolean;
  examples?: string[];
  location_name?: string;
  location_title?: string;
  location_description?: string;
  location_id?: number;
  parent_id?: number;
};

export type TCreateNewCharacterSetup = {
  project_id?: number;
  newLocation?: boolean;
  location?: string;
  notice?: string;
  stage?: number;
  generatedText?: string;
  count?: number;
  aiImgId?: number;
  imageAutoAdd?: boolean;
  personsList?: TPerson[];
  locationsList?: TLocation[];
  examples?: string[];
  person_name?: string;
  person_title?: string;
  person_description?: string;
  person_notice?: string;
  person_person?: number[];
  location_id?: number;
  links?: string;
};

export type TCreateNewQuestSetup = {
  project_id?: number;
  notice?: string;
  stage?: number;
  generatedText?: string;
  count?: number;
  aiImgId?: number;
  imageAutoAdd?: boolean;
  personsList?: TPerson[];
  examples?: string[];
  quest_name?: string;
  quest_description?: string;
  quest_notice?: string;
  person_quest?: number[];
  location_id?: number;
  links?: string;
};

export type TGoogleAutResponse = {
  googleId: string;
  imageUrl: string;
  email: string;
  name: string;
  givenName: string;
  familyName: string;
};

export type TFacebookAutResponse = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  picture: any;
  id: string;
  userID: string;
  expiresIn: number;
  accessToken: string;
  signedRequest: string;
  graphDomain: string;
  data_access_expiration_time: number;
};

export type TUserName = {
  user_id: number;
  user_name: string;
  user_image: string;
};

export type TRelationnsCheck = {
  type: string;
  name: string;
  user_id: number;
  user_name: string;
  problem: string;
  person_id: number;
};

export type TUserRelationList = {
  user_id: number;
  user_name: string;
  relation: string;
};

export type TRelationnsCheckResponse = {
  relationsCheck: TRelationnsCheck[];
  userRelationsList: TUserRelationList[];
  relationsToUsersList: TUserRelationList[];
};

export enum ShowTypes {
  Card = 'card',
  List = 'list',
}

type TEditTextFolders = {
  folder_name: string;
  folder_parent: number;
  folder_id?: number;
};

type TEditStuffFolders = {
  folder_name: string;
  folder_parent: number;
  folder_id?: number;
};

export type TServerSettings = {
  googleLogin: boolean;
  fbLogin: boolean;
  googleClientId: string;
  facebookClientId: string;
  serverFE: string;
};

type TCache = {
  start: number;
  live: number;
  resolved: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Promise<any>;
};

export type TPayment = {
  payment_id?: number;
  payment_system: string;
  payment_target: string;
  payment_target_id: number;
  payment_type: string;
  payment_date: string;
  project_id: number;
  user_id: number;
  payment_ammount: number;
  payment_currency: string;
  payment_system_id: string;
  payment_status: string;
  payment_data: string;
  is_subscribe?: string;
  project_name?: string;
};

let serverUrl = window.location.origin;
if (window.location.origin.split('//')[1].split('.')[0] === 'dev') {
  serverUrl = serverUrl.replace(':3000', '');
}
serverUrl += '/api/';

export default class Api {
  stores: RootStore;
  access: boolean = false;
  firstRun: boolean = false;
  payCode: string = '';
  projectData!: object;
  reloader!: () => void;
  telegram!: TUseTelegram;
  stylesForGenerateImage: string[] = [];
  settings = {
    serverSettings: {} as TServerSettings,
    pricing: {
      free: 0,
      mini: 100,
      full: 300,
    },
    url: {
      server: serverUrl,
      check: 'check',
      checkTG: 'checkTG',
      registration: 'registration',
      login: 'login',
      loginGoogle: 'loginGoogle',
      loginFacebook: 'loginFacebook',
      connectGoogle: 'connectGoogle',
      connectFacebook: 'connectFacebook',
      disconnectSM: 'disconnectSM',
      logout: 'logout',
      forget: 'forget',
      start: 'start',
      profile: 'profile',
      projects: 'projects',
      projectAll: 'projectAll',
      projectsBot: 'projectsBot',
      img: 'img',
      locations: 'location',
      stuffs: 'stuff',
      persons: 'person',
      myPersons: 'myPersons',
      person: 'person',
      statuses: 'status',
      status: 'status',
      news: 'news',
      lastNews: 'lastNews',
      quests: 'quest',
      users: 'user',
      user: 'user',
      allUsers: 'allUsers',
      tasks: 'task',
      taskFlow: 'taskFlow',
      texts: 'text',
      requests: 'request',
      myRequests: 'myRequests',
      relations: 'relations',
      textFolder: 'text_folder',
      stuffFolder: 'stuff_folder',
      search: 'search',
      generateLocation: 'generate_location',
      generatePerson: 'generate_person',
      generateQuest: 'generate_quest',
      generateText: 'generate_text',
      generateImage: 'generate_image',
      checkGenerateImage: 'check_generate_image',
      useGenerateImage: 'use_generate_image',
      stylesForGenerateImage: 'syles_for_generate_image',
      canFreeProject: 'canFreeProject',
      billings: 'billings',
      pay: 'pay',
      payCode: 'payCode',
      getNotice: 'getNotice',
      setNotice: 'setNotice',
      reports: 'reports',
      blackList: 'blackList',
      requestCheck: 'requestCheck',
      requestConvertToPerson: 'requestConvertToPerson',
      checkUserToPerson: 'checkUserToPerson',
      requestFlow: 'requestFlow',
      inviteUser: 'inviteUser',
      postBug: 'bugs',
      bugId: 'bugId',
      userRequest: 'userRequest',
      bot: 'bot',
      registerTelegram: 'registerTelegram',
      checkUserToProject: 'checkUserToProject',
      subscribe: 'subscribe',
      unSubscribe: 'unSubscribe',
      projectCheck: 'projectCheck',
      userSearch: 'userSearch',
      userName: 'userName',
      locationTree: 'locationTree',
      prototypeLARPVectorize: 'prototypeLARP/vectorize',
      prototypeLARPSpeak: 'prototypeLARP/speak',
      prototypeLARPCheck: 'prototypeLARP/check',
      prototypeLARPErase: 'prototypeLARP/erase',
      prototypeLARPSave: 'prototypeLARP/save',
      prototypeLARPCompile: 'prototypeLARP/compile',
      payments: 'payments/',
      liqPay: 'payments/liqPay/',
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  requestCash: { [key: string]: TCache } = {};

  constructor(stores: RootStore) {
    this.stores = stores;
    this.reloader = () => {};
    // eslint-disable-next-line react-hooks/rules-of-hooks
    this.telegram = useTelegram() as TUseTelegram;
    this.telegram.listener(stores.sessionStore);
    const currentUrl: string = window.location.pathname;
    if (currentUrl === '/bot' || this.telegram.isTelegram) {
      this.stores.sessionStore.isBot();
      document.getElementsByTagName('body')[0]?.setAttribute('class', 'bot');
      /*Object.entries(this.telegram.tg.themeParams).map(([key, value]) =>
        document.documentElement.style.setProperty('--tg-' + key, value)
      );*/
      document.documentElement.style.setProperty(
        '--tg-bg-opacity',
        this.telegram.tg.themeParams.bg_color + 'dd'
      );
      console.log('AS TELEGRAM BOT');
    }
    makeObservable(this, {
      access: observable,
      firstRun: observable,
      changeAccess: action,
    });
    setInterval(() => this.cacheWorker(), 1000);
  }
  getSession = () => window.localStorage.session || false;
  setSession = (session: string) => {
    window.localStorage.session = session;
  };

  getLocalConfig = (name: string) => window.localStorage[name];
  setLocalConfig = (name: string, value: string | number | boolean) => {
    window.localStorage[name] = value;
  };
  getProjectType = (): string => this.stores.sessionStore.session.project_type;
  getProjectId = (): number => this.stores.sessionStore.session.user_project;
  projectIsActive = (id?: number): boolean => {
    if (!this.stores.sessionStore?.session?.user_id) return true;
    const projectId = id || this.getProjectId();
    const project = this.stores.projectStore.get(projectId);
    if (!project) return false;
    const { project_pay_status } = project;
    return (
      project_pay_status === ProjectPayTypes.FREE || project_pay_status === ProjectPayTypes.PAYED
    );
  };
  emptyCashe = (url?: string) => {
    if (url) this.removeCacheFor(url);
    else this.requestCash = {};
  };
  cacheWorker = () => {
    const current = new Date().getTime();
    Object.entries(this.requestCash).forEach(([key, data]) => {
      if (data.resolved && (!data.live || current > data.start + data.live * 1000)) {
        this.removeCacheFor(key);
      }
    });
  };
  removeCacheFor = (key: string) => {
    if (this.requestCash[key]) delete this.requestCash[key];
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getData = async (url: string, options: any = {}, data?: object) => {
    console.log(options);
    if (!options?.noCache && this.requestCash[url] !== undefined) {
      console.log('Use cache for', 'api/' + url);
      this.requestCash[url].start = new Date().getTime();
      return this.requestCash[url].data;
    }
    if (options?.noCache) {
      // eslint-disable-next-line no-param-reassign
      delete options.noCache;
    }
    let live = 1;
    if (options?.cache) {
      live = options.cache;
      // eslint-disable-next-line no-param-reassign
      delete options.cache;
    }
    const conf: RequestInit = {
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-Token': this.getSession(),
      },
      ...options,
    };
    if (data) conf.body = JSON.stringify(data);
    try {
      this.requestCash[url] = {
        start: new Date().getTime(),
        data: fetch(this.settings.url.server + url, conf),
        resolved: false,
        live,
      };
      const response = await this.requestCash[url].data;
      this.requestCash[url].resolved = true;
      if (!response.ok) console.warn('Get data', url, response);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.warn(e.message, this.settings.url.server + url);
      return false;
    }
  };

  getJSON = async (url: string, options: object = {}, data?: object) => {
    const response = await this.getData(
      url,
      {
        Accept: 'application/json, text/javascript, */*; q=0.01',
        ...options,
      },
      data
    );
    if (typeof response === 'object') {
      try {
        const returnObject = await response.json();
        if (returnObject?.errors && typeof returnObject?.errors === 'string')
          this.toggleAlertMessage(returnObject?.errors);
        return returnObject;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.warn(url, e.message);
      }
    }
    return {};
  };

  deleteItem = (type: string, id: number | undefined, text?: string) => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-alert
      if (id && (!text || (text && window.confirm(text)))) {
        this.getJSON(type + '/' + id, {
          method: 'DELETE',
        }).then((data) => {
          resolve(data);
        });
      } else reject();
    });
  };

  updateRole = (
    command: string,
    user_id: number,
    project_id: number,
    status?: string,
    text?: string
  ) => {
    return new Promise((resolve, reject) => {
      /*if (this.stores.sessionStore.role() !== UserStatus.Owner) {
        console.log(this.stores.sessionStore.role());
        reject();
        return;
      }*/
      // eslint-disable-next-line no-alert
      if (user_id && (!text || (text && window.confirm(text)))) {
        this.getJSON(
          this.settings.url.projects + '/changeRole/' + user_id,
          {
            method: 'POST',
          },
          { command, project_id, status }
        ).then((data) => {
          resolve(data);
        });
      } else reject();
    });
  };

  changeAccess = (newAccess: boolean) => {
    this.access = newAccess;
  };

  isBot = () => this.stores.sessionStore.appSettings.isBot;
  isBotUser = () => !!this.stores.sessionStore?.session?.user_id;

  checkSession = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const url = this.isBot()
        ? this.settings.url.checkTG + '/' + this.telegram.user.id
        : this.settings.url.check;
      this.getJSON(url).then((data) => {
        if (data.serverSettings) this.settings.serverSettings = data.serverSettings;
        if (data.result) {
          this.changeAccess(true);
          this.stores.sessionStore.update(data.result);
          if (this.isBot()) this.setSession(data.result.user_session);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  };

  noLogin = () => {
    if (!this.stores.sessionStore?.session?.user_id) window.location.href = '/';
  };

  startData = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.start).then((data) => {
        this.projectData = data;
      });
    });
  };

  logout = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.logout).then((data) => {
        this.changeAccess(false);
        this.stores.sessionStore.reset();
        this.setSession('');
        window.location.href = '/';
      });
    });
  };

  setLogin = (email: string, password: string): Promise<TSessionRequest> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.login, { method: 'POST' }, { email, password })
        .then((data: TSessionRequest) => {
          if (data.session) {
            this.setSession(data.session.user_session);
            this.changeAccess(true);
            this.stores.sessionStore.update(data.session);
          }
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  checkGoogleLogin = (loginData: TGoogleAutResponse): Promise<TSessionRequest> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.loginGoogle, { method: 'POST' }, loginData)
        .then((data: TSessionRequest) => {
          if (data.session) {
            this.setSession(data.session.user_session);
            this.changeAccess(true);
            this.stores.sessionStore.update(data.session);
            if (data.session.isRegister) {
              window.location.href = ROUTES.profile;
            } else window.location.reload();
          }
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  checkFacebookLogin = (loginData: TFacebookAutResponse): Promise<TSessionRequest> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.loginFacebook, { method: 'POST' }, loginData)
        .then((data: TSessionRequest) => {
          if (data.session) {
            this.setSession(data.session.user_session);
            this.changeAccess(true);
            this.stores.sessionStore.update(data.session);
          }
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  connectGoogleLogin = (loginData: TGoogleAutResponse): Promise<TSessionRequest> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.connectGoogle, { method: 'POST' }, loginData)
        .then((data: TSessionRequest) => {
          if (data.session) {
            this.setSession(data.session.user_session);
            this.changeAccess(true);
            this.stores.sessionStore.update(data.session);
          }
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  connectFacebookLogin = (loginData: TFacebookAutResponse): Promise<TSessionRequest> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.connectFacebook, { method: 'POST' }, loginData)
        .then((data: TSessionRequest) => {
          if (data.session) {
            this.setSession(data.session.user_session);
            this.changeAccess(true);
            this.stores.sessionStore.update(data.session);
          }
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };
  disconnectSM = (name: string): Promise<TSessionRequest> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.disconnectSM + '/' + name)
        .then((data: TSessionRequest) => {
          if (data.session) {
            this.setSession(data.session.user_session);
            this.changeAccess(true);
            this.stores.sessionStore.update(data.session);
          }
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  register = (regData: TRegisterData): Promise<TSessionRequest> => {
    const { email, password, name, telegramId, fbId } = regData;
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.registration,
        { method: 'POST' },
        { email, password, name, telegramId, fbId }
      )
        .then((data) => {
          if (!data.errors) {
            this.setSession(data.session.user_session);
            this.stores.sessionStore.update(data.session);
            this.changeAccess(true);
            window.localStorage.firstRun = true;
          }
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  forgotPassword = (email: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.forget, { method: 'POST' }, { email })
        .then((data) => {
          console.log('Forgot', email, data);
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  currentProject = (projectId: number) =>
    this.stores.sessionStore.update({
      ...this.stores.sessionStore.session,
      user_project: projectId,
    });

  toBot = (
    telegramId: number,
    message: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { button, button_url, callback_data }: any
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.bot + '/' + telegramId,
        { method: 'POST' },
        { message, button, button_url, callback_data }
      )
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  registerTelegram = (telegram_id: number, code: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.registerTelegram, { method: 'POST' }, { telegram_id, code })
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  getProfileData = (userId: number): Promise<TProfileRequest> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.profile + (userId ? '/' + userId : ''))
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  getUsers = (): Promise<TSimpleUsers> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.user)
        .then((data: TSimpleUsers) => {
          this.stores.userStore.setPlayers(data.players);
          this.stores.userStore.setManagers(data.managers);
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  getUsersName = (id: number): Promise<TUserName> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.userName + '/' + id)
        .then((data) => {
          resolve({ ...data.result, user_id: id } as TUserName);
        })
        .catch((e) => reject(e));
    });
  };

  getAllUsers = (): Promise<TUser[]> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.allUsers)
        .then((data: TUser[]) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  getGraph = (type: string): Promise<TGraphData> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url[type as keyof typeof this.settings.url] + '/graph')
        .then((data: TGraphData) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  getPersonsForProject = (projectId: number): Promise<TPerson[]> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.persons + '/project/' + projectId)
        .then((data: TPerson[]) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  getLocationsForProject = (projectId: number): Promise<TLocation[]> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.locations + '/project/' + projectId)
        .then((data: TLocation[]) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  getSelectProject = (projectId: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.projects + '/select/' + projectId)
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultGet = (url: string, storeName: string, id?: number | string): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url[url as keyof typeof this.settings.url] + (id ? '/' + id : ''))
        .then((data) => {
          //console.log(url, id, data);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (this.stores[storeName as keyof typeof this.stores] as any).update(data);
          //if (id) this.stores[storeName].update(data);
          //else this.stores[storeName].set(data);
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultSet = (data: any, url: string, id?: number): Promise<TSuccessResponse> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url[url as keyof typeof this.settings.url] + (id ? '/' + id : ''),
        { method: 'POST' },
        { ...data }
      )
        .then((result) => {
          resolve(result);
        })
        .catch((e) => reject(e));
    });
  };

  updateProjectData = async (project_id: number) => {
    const projectInfo = this.stores.projectStore.get(project_id);
    if (projectInfo && projectInfo.loaded) return;
    const { result } = await this.getJSON(`${this.settings.url.projectAll}/${project_id}`);
    const { project, persons, locations, statuses } = result;
    this.stores.personStore.update(persons);
    this.stores.locationStore.update(locations);
    this.stores.statusStore.update(statuses);
    this.stores.projectStore.update({ ...project, loaded: true });
  };

  getProjects = (projectId?: number): Promise<TProject | TProject[]> =>
    this.defaultGet('projects', 'projectStore', projectId) as Promise<TProject | TProject[]>;

  getProjectsBot = (projectId?: number): Promise<TProject | TProject[]> =>
    this.defaultGet('projectsBot', 'projectStore', projectId) as Promise<TProject | TProject[]>;

  setProject = (data: TProject): Promise<TSuccessResponse> =>
    this.defaultSet(data, 'projects', data.project_id) as Promise<TSuccessResponse>;

  getLocations = (locationId?: number, projectId?: number): Promise<TLocation | TLocation[]> => {
    if (!this.stores.sessionStore.session?.user_id && locationId) {
      return this.defaultGet(
        'locations',
        'locationStore',
        `project/${projectId || this.stores.sessionStore.session.user_project}/${locationId}`
      ) as Promise<TLocation | TLocation[]>;
    }
    return this.defaultGet('locations', 'locationStore', locationId) as Promise<
      TLocation | TLocation[]
    >;
  };
  setLocation = (data: TLocation): Promise<TSuccessResponseWihNews> =>
    this.defaultSet(
      data,
      'locations',
      data.location_id
    ) as unknown as Promise<TSuccessResponseWihNews>;

  getTasks = (taskId?: number): Promise<TTask | TTask[]> =>
    this.defaultGet('tasks', 'taskStore', taskId) as Promise<TTask | TTask[]>;
  setTask = (data: TTask): Promise<TSuccessResponse> =>
    this.defaultSet(data, 'tasks', data.task_id) as Promise<TSuccessResponse>;

  getTexts = (textId?: number): Promise<TText | TText[]> =>
    this.defaultGet('texts', 'textStore', textId) as Promise<TText | TText[]>;
  setText = (data: TText): Promise<TSuccessResponseWihNews> =>
    this.defaultSet(data, 'texts', data.text_id) as unknown as Promise<TSuccessResponseWihNews>;

  getStuffs = (stuffId?: number): Promise<TStuff | TStuff[]> =>
    this.defaultGet('stuffs', 'stuffStore', stuffId) as Promise<TStuff | TStuff[]>;
  setStuff = (data: TStuff): Promise<TSuccessResponse> =>
    this.defaultSet(data, 'stuffs', data.stuff_id) as Promise<TSuccessResponse>;
  getBillings = (): Promise<TBilling[]> =>
    this.defaultGet('billings', 'billingsStore', 0) as Promise<TBilling[]>;

  getMyPersons = (): Promise<TPerson | TPerson[]> => {
    return this.defaultGet('myPersons', 'personStore') as Promise<TPerson | TPerson[]>;
  };
  getPersons = (personId?: number, projectId?: number): Promise<TPerson | TPerson[]> => {
    if (!this.stores.sessionStore.session?.user_id && personId) {
      return this.defaultGet(
        'persons',
        'personStore',
        `project/${projectId || this.stores.sessionStore.session.user_project}/${personId}`
      ) as Promise<TPerson | TPerson[]>;
    }
    return this.defaultGet('persons', 'personStore', personId) as Promise<TPerson | TPerson[]>;
  };
  setPerson = (data: TPerson): Promise<TSuccessResponseWihNews> =>
    this.defaultSet(data, 'persons', data.person_id) as unknown as Promise<TSuccessResponseWihNews>;

  getQuests = (questId?: number): Promise<TQuest | TQuest[]> =>
    this.defaultGet('quests', 'questStore', questId) as Promise<TQuest | TQuest[]>;
  setQuest = (data: TQuest): Promise<TSuccessResponse> =>
    this.defaultSet(data, 'quests', data.quest_id) as Promise<TSuccessResponse>;

  getRequestsForUser = (userId?: number): Promise<TRequest | TRequest[]> =>
    this.defaultGet('userRequest', 'requestStore', userId) as Promise<TRequest | TRequest[]>;
  getRequests = (requestId?: number): Promise<TRequest | TRequest[]> =>
    this.defaultGet('requests', 'requestStore', requestId) as Promise<TRequest | TRequest[]>;
  setRequest = (data: TRequest): Promise<TSuccessResponse> =>
    this.defaultSet(data, 'requests', data.request_id) as Promise<TSuccessResponse>;

  getStatuses = (statusId?: number): Promise<TStatus | TStatus[]> =>
    this.defaultGet('statuses', 'statusStore', statusId) as Promise<TStatus | TStatus[]>;
  setStatus = (data: TStatus): Promise<TSuccessResponse> =>
    this.defaultSet(data, 'statuses', data.status_id) as Promise<TSuccessResponse>;

  getNews = (newsId?: number): Promise<TNews | TNews[]> =>
    this.defaultGet('news', 'newsStore', newsId) as Promise<TNews | TNews[]>;
  getNewsBot = (newsId?: number): Promise<TNews | TNews[]> =>
    this.defaultGet('lastNews', 'newsStore', newsId) as Promise<TNews | TNews[]>;
  setNews = (data: TNews): Promise<TSuccessResponse> =>
    this.defaultSet(data, 'news', data.news_id) as Promise<TSuccessResponse>;
  checkUserToProject = (projectId: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.checkUserToProject + '/' + projectId)
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };
  subscribeToProject = (projectId: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.subscribe + '/' + projectId)
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };
  unSubscribeToProject = (projectId: number): Promise<string> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.unSubscribe + '/' + projectId)
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };
  updateProfile = (data: TProfile, userId?: number): Promise<TSuccessResponse> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.profile + (userId ? '/' + userId : ''),
        { method: 'POST' },
        { ...data }
      )
        .then((result) => {
          resolve(result);
        })
        .catch((e) => reject(e));
    });
  };

  removeImg = (fileName: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const imgId = fileName.split('.')[0];
      if (Number.isNaN(Number(imgId))) {
        reject(Error(fileName + ' wrong'));
        return;
      }
      this.getJSON(this.settings.url.img + '/' + imgId, { method: 'DELETE' })
        .then((result) => {
          resolve(result);
        })
        .catch((e) => reject(e));
    });
  };

  isManager = (projectId?: number, ownerOnly?: boolean): boolean => {
    const role = this.stores.sessionStore.role();
    if (!projectId) return role === UserStatus.Admin;
    const userRole = this.stores.sessionStore.session.user_role.find(
      (u) => u.project_id === projectId
    );
    if (!userRole) return false;
    console.log(userRole);
    if (ownerOnly) return userRole.status === UserStatus.Owner;
    return userRole.status === UserStatus.Manager || userRole.status === UserStatus.Owner;
  };

  getLinks = (type: string, id: number, noCache: boolean = false): Promise<TLinkData> => {
    return new Promise((resolve, reject) => {
      this.getJSON(`${type}/link/${id}`, noCache ? { noCache } : { cache: 3 })
        .then((data) => {
          resolve(data.success as TLinkData);
        })
        .catch((e) => reject(e));
    });
  };

  setLink = (
    setup: TObjectLink,
    store?: LinkStore,
    text_description?: string
  ): Promise<TLink[]> => {
    const { fromId, toId, type, subType, description, name } = setup;
    return new Promise((resolve, reject) => {
      this.getJSON(`${type}/link/${fromId}`, { method: 'POST' }, setup)
        .then((data) => {
          if (store && subType)
            store.add(
              {
                [subType]: [
                  text_description
                    ? { fromId, toId, description, name, text_description }
                    : { fromId, toId, description, name },
                ],
              },
              fromId
            );
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  setPersonLink = (setup: TPersonLink): Promise<TLink[]> => {
    const { fromId, toId, description, status, nameFrom, nameTo, type } = setup;
    return new Promise((resolve, reject) => {
      this.getJSON(`person/link/${fromId}`, { method: 'POST' }, { subType: 'person', ...setup })
        .then((data) => {
          this.stores.personLinks.add(
            {
              person: [{ fromId, toId, description, status, nameFrom, nameTo, type }],
            },
            fromId
          );
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  removeLink = (setup: TObjectLink, store?: LinkStore): Promise<TLink[]> => {
    const { fromId, toId, type, subType } = setup;
    return new Promise((resolve, reject) => {
      const url = `${type}/link/${fromId}`;
      this.emptyCashe(url);
      this.getJSON(url, { method: 'DELETE', noCache: true }, setup)
        .then((data) => {
          if (store && subType) store.remove({ fromId, toId, type: subType });
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  loadRelations = (id?: number): Promise<TLinkData> => {
    return new Promise((resolve, reject) => {
      if (this.stores.sessionStore.session?.user_id)
        this.getJSON(this.settings.url.relations + `${id ? '/' + id : ''}`)
          .then((data) => {
            this.stores.relationLinks.add(data as TRelationLink[], true);
            resolve(data);
          })
          .catch((e) => reject(e));
      resolve({});
    });
  };

  getRelations = (source?: number, target?: number): Promise<TUserToUser[]> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.relations + `${source ? '/' + source : ''}${target ? '/' + target : ''}`
      )
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  setRelation = (target: number, type: string, source?: number): Promise<TUserToUser[]> => {
    const relationData = { source, target, type };
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.relations, { method: 'POST' }, relationData)
        .then((data) => {
          const s = source || this.stores.sessionStore.session.user_id;
          this.stores.relationLinks.add([{ target, source: s, type } as TRelationLink]);
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  deleteRelation = (target: number, source?: number, type?: string): Promise<TUserToUser[]> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.relations + `${target ? '/' + target : ''}${source ? '/' + source : ''}`,
        {
          method: 'DELETE',
        }
      )
        .then((data) => {
          const s = source || this.stores.sessionStore.session.user_id;
          this.stores.relationLinks.remove({ target, source: s, type });
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  getTextFolders = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.textFolder)
        .then((data) => {
          this.stores.textFolderStore.set(data as TTextFolder[]);
          resolve(true);
        })
        .catch((e) => reject(e));
    });
  };

  deleteTextFolders = (folderId: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.textFolder + '/' + folderId, { method: 'DELETE' })
        .then((data) => {
          this.stores.textFolderStore.remove(folderId);
          this.getTextFolders().then((result) => resolve(true));
        })
        .catch((e) => reject(e));
    });
  };

  moveTextFolders = (sourceId: number, destId: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.textFolder + '/move/' + sourceId + '/' + destId, {
        method: 'GET',
      })
        .then(() => {
          this.stores.textStore.data.forEach((text: TText) => {
            resolve(true);
            if (text.folder_id !== sourceId) return;
            this.stores.textStore.update({ ...text, folder_id: destId });
          });
        })
        .catch((e) => reject(e));
    });
  };

  editTextFolders = (folderId: number, name: string, parentFolder: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const postData: TEditTextFolders = {
        folder_name: name,
        folder_parent: parentFolder,
      };
      if (folderId) postData.folder_id = folderId;
      this.getJSON(
        this.settings.url.textFolder + (folderId ? '/' + folderId : ''),
        { method: 'POST' },
        postData
      )
        .then((data) => {
          if (folderId)
            this.stores.textFolderStore.update({
              ...this.stores.textFolderStore.get(folderId),
              folder_name: name,
              folder_parent: parentFolder,
              folder_id: folderId,
            });
          else
            this.stores.textFolderStore.add({
              folder_name: name,
              folder_parent: parentFolder,
              folder_id: folderId || data.success,
              folder_count: 0,
              text_count: 0,
            });
          resolve(true);
        })
        .catch((e) => reject(e));
    });
  };

  getStuffFolders = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.stuffFolder)
        .then((data) => {
          this.stores.stuffFoldersStore.set(data as TStuffFolder[]);
          resolve(true);
        })
        .catch((e) => reject(e));
    });
  };

  deleteStuffFolders = (folderId: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.stuffFolder + '/' + folderId, { method: 'DELETE' })
        .then((data) => {
          this.stores.stuffFoldersStore.remove(folderId);
          this.getStuffFolders().then((result) => resolve(true));
        })
        .catch((e) => reject(e));
    });
  };

  moveStuffFolders = (sourceId: number, destId: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.stuffFolder + '/move/' + sourceId + '/' + destId, {
        method: 'GET',
      })
        .then(() => {
          this.stores.stuffStore.data.forEach((stuff: TStuff) => {
            resolve(true);
            if (stuff.folder_id !== sourceId) return;
            this.stores.stuffStore.update({ ...stuff, folder_id: destId });
          });
        })
        .catch((e) => reject(e));
    });
  };

  editStuffFolders = (folderId: number, name: string, parentFolder: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const postData: TEditStuffFolders = {
        folder_name: name,
        folder_parent: parentFolder,
      };
      if (folderId) postData.folder_id = folderId;
      this.getJSON(
        this.settings.url.stuffFolder + (folderId ? '/' + folderId : ''),
        { method: 'POST' },
        postData
      )
        .then((data) => {
          if (folderId)
            this.stores.stuffFoldersStore.update({
              ...this.stores.stuffFoldersStore.get(folderId),
              folder_name: name,
              folder_parent: parentFolder,
              folder_id: folderId,
            });
          else
            this.stores.stuffFoldersStore.add({
              folder_name: name,
              folder_parent: parentFolder,
              folder_id: folderId || data.success,
              folder_count: 0,
              stuff_count: 0,
            });
          resolve(true);
        })
        .catch((e) => reject(e));
    });
  };

  search = (searchString: string): Promise<TSearchResult> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.search + '/' + searchString)
        .then((data) => resolve(data as TSearchResult))
        .catch((e) => reject(e));
    });
  };

  generateText = (
    text: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.generateText,
        { method: 'POST' },
        { text, project_id: this.stores.sessionStore.session.user_project }
      )
        .then((data) => resolve(data))
        .catch((e) => reject(e));
    });
  };

  pay = (
    info: string,
    billingSystem: string,
    amount: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.pay,
        { method: 'POST' },
        { info, billingSystem, amount, payCode: this.payCode }
      )
        .then((data) => {
          this.payCode = '';
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };
  getPayCode = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.payCode)
        .then((data) => {
          this.payCode = data;
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  generateImage = (
    text: string,
    type: string,
    id: number,
    style: string,
    negative: string,
    widht: number = 800,
    height: number = 600
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.generateImage,
        { method: 'POST' },
        {
          prompt: text,
          type,
          id,
          style,
          negative,
          widht,
          height,
          project_id: this.stores.sessionStore.session.user_project,
        }
      )
        .then((data) => resolve(data))
        .catch((e) => reject(e));
    });
  };

  useGenerateImage = (type: string, id: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.useGenerateImage, { method: 'POST' }, { type, id })
        .then((data) => resolve(data.result))
        .catch((e) => reject(e));
    });
  };

  checkGenerateImage = (img_id: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.checkGenerateImage + '/' + img_id)
        .then((data) => resolve(data.result))
        .catch((e) => reject(e));
    });
  };

  taskFlow = (newFlow: string, task_id: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.taskFlow, { method: 'POST' }, { newFlow, task_id })
        .then((data) => resolve(data.result))
        .catch((e) => reject(e));
    });
  };
  getStylesForGenerateImage = (): Promise<string[]> => {
    return new Promise((resolve, reject) => {
      if (!this.stylesForGenerateImage.length) {
        this.getJSON(this.settings.url.stylesForGenerateImage)
          .then((data) => {
            const result: string[] = data.result as string[];
            this.stylesForGenerateImage = result;
            //console.log(result);
            resolve(result);
          })
          .catch((e) => reject(e));
      } else resolve(this.stylesForGenerateImage);
    });
  };

  canFreeProject = (): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.canFreeProject)
        .then((data) => {
          const result: boolean = data.result as boolean;
          resolve(result);
        })
        .catch((e) => reject(e));
    });
  };

  setNotice = (notice: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.setNotice, { method: 'POST' }, { notice })
        .then((data) => {
          const result: boolean = data.result as boolean;
          if (result) this.stores.sessionStore.setNotice(notice);
          resolve(result);
        })
        .catch((e) => reject(e));
    });
  };

  getNotice = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      const st = this.stores.sessionStore.getNotice();
      if (st) {
        resolve(st);
        return;
      }
      this.getJSON(this.settings.url.getNotice)
        .then((data) => {
          resolve(data.notice);
        })
        .catch((e) => reject(e));
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getReport = (type: string, data: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.reports + '/' + type,
        { method: 'POST' },
        { project_id: this.stores.sessionStore.session.user_project, ...data }
      )
        .then((result) => {
          resolve(result.result);
        })
        .catch((e) => reject(e));
    });
  };

  getPDFURL = (file: string) => this.settings.url.server + 'pdf/' + file;

  updateBL = (
    type: string,
    list: string,
    user_id: number,
    prompt: string = ''
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-alert
      if (user_id && (!prompt || (prompt && window.confirm(prompt)))) {
        this.getJSON(this.settings.url.blackList, { method: 'POST' }, { user_id, type, list })
          .then((data) => {
            resolve(data.result);
          })
          .catch((e) => reject(e));
      } else resolve(false);
    });
  };

  requestCheck = (request_id: number): Promise<TRelationnsCheckResponse> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.requestCheck + '/' + request_id)
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  requestConvertToPerson = (request_id: number, useAi: boolean): Promise<number> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.requestConvertToPerson + (useAi ? 'AI' : '') + '/' + request_id
      )
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  checkUserToPerson = (person_id: number, user_id: number): Promise<TRelationnsCheckResponse> => {
    return new Promise((resolve, reject) => {
      this.getJSON(`${this.settings.url.checkUserToPerson}/${person_id}/${user_id}`)
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  rejectRequest = (request_id: number, reject_notice: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.requestFlow,
        { method: 'POST' },
        { request_id, mode: 'reject', reject_notice }
      )
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  inviteRequest = (person_id: number, user_id: number, invite_notice: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.inviteUser,
        { method: 'POST' },
        { person_id, user_id, invite_notice }
      )
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  inviteFlow = (request_id: number, mode: string): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.requestFlow,
        { method: 'POST' },
        { request_id, mode, invite: true }
      )
        .then((data) => {
          const oldValue = this.stores.requestStore.get(request_id);
          this.stores.requestStore.update({ ...oldValue, request_flow: mode });
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  searchUser = (name: string): Promise<TUserSearch[]> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.userSearch, { method: 'POST' }, { name })
        .then((data) => {
          if (data.result?.length) this.stores.sessionStore.appSettings.userSearch = data.result;
          resolve(data.result as TUserSearch[]);
        })
        .catch((e) => reject(e));
    });
  };

  successRequest = (
    request_id: number,
    success_notice: string,
    reject_others: boolean
  ): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.requestFlow,
        { method: 'POST' },
        { request_id, mode: 'success', success_notice, reject_others }
      )
        .then((data) => {
          resolve(data.result);
        })
        .catch((e) => reject(e));
    });
  };

  generateLocation = (
    setup: TCreateNewLocationSetup
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<TCreateLocationReturn[]> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.generateLocation, { method: 'POST' }, setup)
        .then((data) => {
          /*const result = data.result.map((d:TCreateLocationReturn)=>{
              d.aiImgId = 1000 + Math.round(Math.random() * 10000);

            })
            resolve(data.result as TCreateLocationReturn[])*/
          const result: TCreateLocationReturn[] = data.result.map((item: TCreateLocationReturn) => {
            /*const res: TCreateLocationReturn = this.splitGeneratedPersonText(
                item.text || '',
                data.commands
              );
              res.text = item.text;
              res.aiImgId = 1000 + Math.round(Math.random() * 10000);*/
            const res: TCreateLocationReturn = {
              ...item,
              aiImgId: setup.aiImgId || 1000 + Math.round(Math.random() * 10000),
            };
            return res;
          });
          resolve(result);
        })
        .catch((e) => reject(e));
    });
  };

  generatePerson = (setup: TCreateNewCharacterSetup): Promise<TCreatePersonReturn[] | false> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.generatePerson, { method: 'POST' }, setup)
        .then((data) => {
          if (data.result?.error) {
            this.toggleAlertMessage(data.result?.error);
            resolve(false);
          } else {
            const result: TCreatePersonReturn[] = data.result.map((item: TCreatePersonReturn) => {
              const res: TCreatePersonReturn = {
                ...item,
                aiImgId: setup.aiImgId || 1000 + Math.round(Math.random() * 10000),
              };
              return res;
            });
            resolve(result);
          }
        })
        .catch((e) => reject(e));
    });
  };

  generateQuest = (setup: TCreateNewQuestSetup): Promise<TCreateQuestReturn[]> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.generateQuest, { method: 'POST' }, setup)
        .then((data) => {
          const result: TCreateQuestReturn[] = data.result.map((item: TCreateQuestReturn) => {
            const res: TCreateQuestReturn = {
              ...item,
              aiImgId: setup.aiImgId || 1000 + Math.round(Math.random() * 10000),
            };
            return res;
          });
          resolve(result);
        })
        .catch((e) => reject(e));
    });
  };

  getBugId = (): Promise<number> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.bugId)
        .then((data) => {
          resolve(data.result || 0);
        })
        .catch((e) => reject(e));
    });
  };

  projectCheck = (status: string, projectId: number): Promise<TCheckResult> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.projectCheck + '/' + status + '/' + projectId)
        .then((data: TCheckResult) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };
  getLocationTree = (pId?: number): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const projectId = pId || this.stores.sessionStore.session.user_project;
      /*if(!projectId || this.stores.sessionStore.session.project_type !== ProjectTypes.LORE){
        resolve(false);
        return;
      }*/
      this.getJSON(this.settings.url.locationTree + '/project/' + projectId)
        .then((data: TLocationTree[]) => {
          this.stores.locationStore.setLocationTree(data || []);
          resolve(true);
        })
        .catch((e) => reject(e));
    });
  };
  postBug = (bug_text: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      const { userAgent, language } = window.navigator;
      const { height, width, pixelDepth, orientation } = window.screen;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const bug_device = JSON.stringify({
        userAgent,
        language,
        height,
        width,
        pixelDepth,
        orientation: orientation.type,
      });
      this.getJSON(
        this.settings.url.postBug,
        { method: 'POST' },
        {
          bug_text,
          bug_url: window.location.pathname,
          bug_device,
          project_id: this.stores.sessionStore.session.user_project || 0,
          user_id: this.stores.sessionStore.session.user_id || 0,
        }
      )
        .then((data) => {
          resolve();
        })
        .catch((e) => reject(e));
    });
  };

  splitGeneratedPersonText(
    text: string,
    commands: { [key: string]: keyof TCreatePersonReturn }
  ): TCreatePersonReturn {
    const result = {} as TCreatePersonReturn;
    text.split('\n').forEach((t: string) => {
      const tmp = t.trim().split(':');
      const type = tmp[0].trim();
      tmp.shift();
      const tt: string = tmp.join(':');
      if (commands[type]) {
        const key = commands[type];
        result[key] = tt
          .replaceAll('<', '<b:')
          .replaceAll('>', '</b>')
          .replaceAll('<b:', '<b>')
          .replaceAll('[', '')
          .replaceAll(']', '')
          .trim() as never;
      }
    });
    return result;
  }

  // eslint-disable-next-line no-alert
  prompt = (message?: string) => window.prompt(message);

  // eslint-disable-next-line no-alert
  confirm = (message?: string) => window.confirm(message);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  simplyHTMLItem = (item: any, field: string) => {
    const el = document.createElement('DIV');
    el.innerHTML = item[field];
    return { ...item, [field]: el.innerText };
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  simplyHTMLArray = (data: any[], field: string) =>
    data.map((item) => this.simplyHTMLItem(item, field));

  showDialog = (type: string, id: number) => this.stores.sessionStore.openDialog(type, id);
  toggleImageGenerateDialog = (
    newSatatus: boolean,
    text: string | undefined,
    calback: () => void | undefined
  ) => {
    this.stores.sessionStore.toggleImageGenerateDialog(newSatatus, text, calback);
  };
  toggleLocationGenerateDialog = (newSatatus: boolean) => {
    this.stores.sessionStore.toggleLocationGenerateDialog(newSatatus);
  };
  togglePersonGenerateDialog = (newSatatus: boolean) => {
    this.stores.sessionStore.togglePersonGenerateDialog(newSatatus);
  };
  toggleQuestGenerateDialog = (newSatatus: boolean) => {
    this.stores.sessionStore.toggleQuestGenerateDialog(newSatatus);
  };
  toggleTextGenerateDialog = (newSatatus: boolean) => {
    this.stores.sessionStore.toggleTextGenerateDialog(newSatatus);
  };
  toggleNoticeDialog = (newSatatus: boolean) => {
    this.stores.sessionStore.toggleNoticeDialog(newSatatus);
  };
  toggleFastActions = (newSatatus: boolean) => {
    this.stores.sessionStore.toggleFastAction(newSatatus);
  };
  togglePayDialog = (newSatatus: boolean) => {
    this.stores.sessionStore.togglePayDialog(newSatatus);
  };
  toggleAlertMessage = (text: string | false) => {
    this.stores.sessionStore.toggleAlertMessage(text);
  };
  toggleLoginDialog = (newSatatus: boolean) => {
    this.stores.sessionStore.toggleLoginDialog(newSatatus);
  };
  bugDialog = (newSatatus: boolean) => {
    this.stores.sessionStore.bugDialog(newSatatus);
  };
  toggleRequestCheckDialog = (requestId: number) => {
    this.stores.sessionStore.toggleRequestCheckDialog(requestId);
  };
  toggleRolePropositionDialog = (personId: number) => {
    this.stores.sessionStore.toggleRolePropositionDialog(personId);
  };
  togglePricingGenerateDialog = (
    calback: ((name: string | false) => void) | false,
    _default?: string | false
  ) => {
    this.stores.sessionStore.togglePricingGenerateDialog(calback, _default);
  };
  showImagePopup = (image: string, type: string = 'img') => {
    let im = image + '';
    if (im && type === 'generation') im += `?${Math.round(Math.random() * 10000)}`;
    if (im) this.stores.sessionStore.showImagePopup(`${type}/${im}`);
    else this.stores.sessionStore.showImagePopup('');
  };
  closeDialog = () => this.stores.sessionStore.closeDialog();
  historyDialog = () => this.stores.sessionStore.dialogBack();
  timeFormat = (time: Date | undefined | string): string => {
    if (!time) return '';
    const momentTime = moment(time);
    return `${momentTime.fromNow()} ${momentTime.format('DD.MM.YY')} ${i18n.t(
      'global.at'
    )} ${momentTime.format('HH:mm')}`;
  };
  selectProject = (projectId: number, calback: () => void) => {
    if (!projectId) return;
    this.getSelectProject(projectId).then(calback);
  };
  dateDiff(date: moment.Moment | string, type: string = 'days'): number {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return moment().diff(date, type as any) * -1;
  }

  getProjectSettings(projectId: number): TProjectSettings {
    if (!projectId) return {} as TProjectSettings;
    const projectData = this.stores.projectStore.settings(projectId);
    if (projectData) return projectData;
    return {} as TProjectSettings;
  }

  canTextAi(projectId?: number, onlyUser: boolean = false): boolean {
    const userSettings = this.stores.sessionStore.session.user_settings;
    let userAI = false;
    if (userSettings) {
      if (userSettings.useAI && userSettings.openAiKey) userAI = true;
    }
    if (onlyUser) return userAI;
    const settings = this.getProjectSettings(
      projectId || this.stores.sessionStore.session.user_project
    );
    let result = false;
    if (settings) {
      if (settings.useAI && settings.openAiKey) result = true;
    }
    return result || userAI;
  }

  canImageAi(projectId?: number): number {
    const settings = this.getProjectSettings(
      projectId || this.stores.sessionStore.session.user_project
    );
    if (settings) {
      if (settings?.useImageAI) return settings?.imageAIAvailable ? 2 : 1;
    }
    return 0;
  }

  projectLang(projectId?: number): string {
    const settings = this.getProjectSettings(
      projectId || this.stores.sessionStore.session.user_project
    );
    if (settings?.lang) return settings.lang;
    return 'ua';
  }

  isUserHavePerson(person_id: number): boolean {
    /*const found = this.stores.personStore.data.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (person: TPerson) => person.user_id === this.stores.sessionStore.session.user_id
    );
    return !!found;*/
    const person: TPerson = this.stores.personStore.get(person_id);
    if (!person) return false;
    return person.user_id === this.stores.sessionStore.session.user_id;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  useParalax(settings: any = {}, className: string = 'paralax') {
    if (!this.isBot()) return;
    setTimeout(() => {
      const images = document.querySelectorAll('.' + className);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, no-new, new-cap
      new simpleParallax(images as any as Element[], {
        orientation: 'down',
        scale: 1.75,
        ...settings,
      });
    }, 200);
  }

  cut(text: string, words: number = 20, add: string = '...') {
    const word = text.split(' ');
    if (word.length > words) return word.splice(0, words).join(' ') + (add || '');
    return text;
  }

  prototypeLARPCheckProject = () // eslint-disable-next-line @typescript-eslint/no-explicit-any
  : Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.prototypeLARPCheck)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  prototypeLARPEraseProject = () // eslint-disable-next-line @typescript-eslint/no-explicit-any
  : Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.prototypeLARPErase)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  prototypeLARPSendQuestion = (
    ask: string,
    state: TCommandInfo[] | false,
    idList: { [key: string]: TIdInfo } | false
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value: any = { ask };
      if (state !== false) value.state = JSON.stringify(state);
      if (idList !== false) value.idList = JSON.stringify(idList);
      this.getJSON(this.settings.url.prototypeLARPSpeak, { method: 'POST' }, value)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };

  prototypeLARPSave = (
    state: TCommandInfo[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.prototypeLARPSave,
        { method: 'POST' },
        { state: JSON.stringify(state) }
      )
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };
  prototypeLARPCompile = (
    state: TCommandInfo[]
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Promise<any> => {
    return new Promise((resolve, reject) => {
      this.getJSON(
        this.settings.url.prototypeLARPCompile,
        { method: 'POST' },
        { state: JSON.stringify(state) }
      )
        .then((data) => {
          resolve(data);
        })
        .catch((e) => reject(e));
    });
  };
  liqPayButtonTest = (): Promise<string> => {
    return new Promise((resolve, reject) => {
      this.getData(this.settings.url.liqPay + 'test').then(async (response) => {
        resolve(await response.text());
      });
    });
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  liqSubscribePayButton = (data: any): Promise<string> => {
    return new Promise((resolve, reject) => {
      this.getData(this.settings.url.liqPay + 'subscribe', { method: 'POST' }, data).then(
        async (response) => {
          resolve(await response.text());
        }
      );
    });
  };
  getPaymentsList = (): Promise<TPayment[]> => {
    return new Promise((resolve, reject) => {
      this.getJSON(this.settings.url.payments + 'list').then((data) => {
        resolve(data.result);
      });
    });
  };
}
