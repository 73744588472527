/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-danger */
import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { useStores } from 'services/useStore';

const gameTotallPlayers = 50;
const gameVIPPlayers = 15;
const gameTime = 2;
const gameText2 = `You are a master who makes LARP games.
You need to set a game based on the world of Andrzej Sapkowski's The Witcher.
The game will be for ${gameTotallPlayers} people, of which ${gameVIPPlayers} are the key characters of the book and the rest are just residents of that world.
The game will last ${gameTime} full days.
Think about what passage from the book you would use for the game. Describe it.
And also choose from the book roles for ${gameVIPPlayers}these named characters (who were mentioned in the work)
and come up with their main goal for the game (describe who they will interact with most, and what joint quests they might have).
Describe what you think I will play and what the main plots and conflicts will be. what groups will there be (magicians, craftsmen...) and how they interact with each other, make friends, quarrel...
Also, think about what locations you will need to organize and which of the players will live in them and what surrounding things need to be installed there (for example, an alchemist’s shop needs alchemical utensils, a baker needs a rolling pin and an apron...)
And at the end, come up with events or quests that will fill the game days.
The answer must be in JSON format, this is important! You don’t need to write anything else except JSON itself! The note must begin with {
All fields in the response must be in Russian (unless another language is indicated).
Here's the structure of the response:
{
   gameName: "the name of the game you came up with",
   gameTitle: "a brief description of what the game is about and what it is based on",
   gameDescription:"advertising text to attract players, at least 2 paragraphs",
   gameImage: "imagine that you need to draw a poster for this game, write here a request to the Dall-e neural network in English so that it draws it",
   locationList:[list all the locations you came up with],
   grousList:[list all the groups you came up with],
   eventList:[list all the events you came up with],
   stuffList:[list all the things you came up with],
   personList: [list all the characters you came up with],
   locations:[
     {
       locationName:"the name of the first location you came up with",
       locationTitle: "a brief description of the location, what is there",
       locationDescription: "describe what buildings in this location will need to be done, what to provide and at what time of the game it will be used",
       locationImage: "imagine that you need to draw a description for this location, write here a request to the Dall-e neural network in English so that it draws it",
       locationPersons:[here just list all those who live in this location or will be in this location for the longest time]
     },
     {... just like all the other locations you came up with}
   ],
   groups:[
     {
       groupName: "the name of the first group you came up with",
       groupTitle: "a brief description of the group, who is included in it",
       groupDescription: "describe how this group interacts with other groups",
       groupImage: "imagine that you need to draw this group, write here a request to the Dall-e neural network in English so that it draws it"
     },
     {... just like all the other groups you came up with}
   ],
   persons:[
     {
       personName: "the name of the first character you came up with",
       personTitle: "a brief description of the character known to everyone",
       personDescription: "this text will be available only to the player who has received this role, here describe everything that the player needs to know, including information that is secret to others,"
       personSecrets: "this text will be available to game co-players in it describe what your intention was when creating this character, what he should achieve, and who he really is if he is pretending to be someone",
       personImage: "imagine that you need to draw this character in his natural environment, write here a request to the Dall-e neural network in English so that it draws this,"
       personLocation: "indicate the name of the location to which this character belongs (lives there, or most often happens)",
       personGroups:[here list one or more groups to which this character belongs],
       personRelations: [here just list all those who are connected with this character through common plots or other interactions]
     },
     {... same for all the other characters you came up with}
   ],
   stuffs:[
     {
       stuffName:"the name of the surrounding element that you came up with",
       stuffTitle:"a brief description of this item",
       stuffDescription:"describe where it will be located, location, or character, if it is on it",
       stuffImage: "imagine that you need to draw this thing, write here a request to the Dall-e neural network in English so that it draws it"
     },
     {... just like all the other things you came up with}
   ],
   events:[
     {
       eventName:"the name of the event or quest you came up with",
       eventTitle:"brief description of the quest or event",
       eventDescription: "a description available only to masters, what exactly will happen in detail at this event or quest, what plot twists and when it should start",
       eventImage: "imagine that you need to draw a description of an event, write here a request to the Dall-e neural network in English so that it draws it",
       eventPersons:[here list all the characters who will take part in this event or quest, or leave empty if this event or quest is for anyone]
     },
     {... just like all the others you came up with}
    ]
  }`;
const gameText = `Ты мастер который делает LARP игры. 
Тебе надо поставить игру по миру произведения Анджея Сапковского "Ведьмак".  
Игра будет на ${gameTotallPlayers} человек из котрых ${gameVIPPlayers} это ключевые персонажи книги а остальные - просто жители того мира. 
Игра будет длится ${gameTime} полных дня. 
Придумай какой отрезок из книги ты бы использовал для игры. Опиши его. 
А также выбери из книги роли для ${gameVIPPlayers}ти именных персонажей (котрые упоминались в произведении) 
и придумай их основную цель на игре (опиши с кем они больше всего будут взаимодействовать, и какие у них могут быть совместные квесты). 
Опиши во что, по твоему, буду играть и какие будут основные сюжеты и конфликты. какие будут группы (маги, ремесленики...) и как они между собой взаимодействуют, дружат, враждуют...
Также придумай какие локации тебе надо будет организовать и кто из игроков в них будет жить и какие антуражные вещи надо туда установить (например в лавку алхимика нужна алхимическая посуда, пекарю - скалка и фартук ...)
И в конце придумай ивенты или квесты которые будут наполнять игровые дни.
Ответ обязательно должен быть в JSON формате, это важно! Кроме самомго JSON ничего больше писать не надо! Ответ должен начинаться с {
Все поля в ответе должны быть на русском (если в них не указано иного языка).
Вот структура ответа:
{
  gameName:"название игры кторое ты придумал",
  gameTitle:"краткое описание о чем игра и на основе чего сделана",
  gameDescription:"рекламный текст для привлечения игроков, не менее 2х абзацев",
  gameImage:"представь что тебе надо нарисовать постер к это игре, напиши тут запрос к нейросети Dall-e на английском что-бы она нарисовала его",
  locationList:[перечисли все локации которые ты придумал],
  grousList:[перечисли все группы которые ты придумал],
  eventList:[перечисли все ивенты которые ты придумал],
  stuffList:[перечисли все вещи которые ты придумал],
  personList:[перечисли всех персонажей которых ты придумал]
}`;

const baseText = `Ты мастер который делает LARP игры. 
Тебе надо поставить игру по миру произведения Анджея Сапковского "Ведьмак".  
Игра будет на ${gameTotallPlayers} человек из котрых ${gameVIPPlayers} это ключевые персонажи книги а остальные - просто жители того мира. 
Игра будет длится ${gameTime} полных дня. Ранее ты придумал вот такую игру и ее описание:

Название: "Ведьмак: Партизанская тропа",
Описание: "Игра на основе мира Ведьмака, где игроки осуществляют партизанскую борьбу против захватчиков.",
Вступите в мир, где судьбы героев переплетены с интригами и магией. Выживет ли ваш персонаж в хаосе войны и политических уловок, или будете ли вы тем, кто приведет к миру? Двухдневное погружение в ролевую игру, полную приключений, где каждый выбор может изменить ход истории.

В игре будут
Локации: "Лагерь партизан", "Захваченный город", "Тайная лаборатория магов", "Лесной массив", "Руины древнего храма"
Группы: "Партизаны", "Жители города", "Захватчики", "Маги", "Лесные чудовища"
События: "Спасение пленных", "Освобождение города", "Сбор редких трав для зельев", "Защита от нападения чудовищ", "Вторжение в лабораторию магов"
Предметы: "Зелья и эликсиры", "Древние руны", "Волшебные амулеты", "Оружие и доспехи", "Ритуальные предметы"
Персонажи: "Геральт", "Йеннифэр", "Цири", "Трисс", "Данделлион (Йаскир)", "Филиппа Эйльхарт", "Вильгефорц", "Весемир", "Кейра Мец", "Золтан Хивай", "Шани", "Фрингилла Виго", "Лето из Гулета", "Эмгыр вар Эмрейс", "Эредин Бреак Глас"

`;
const locationText = `
На основе этих знаний опиши каждую локацию из списка за следующим шаблоном:
{
  locations:[
    {
      locationName:"название первой локации которую ты придумал",
      locationTitle:"краткое описание локации, что там находится",
      locationDescription:"опиши какие постройки в этой локации, надо будет сделать, что предусмотреть и в какое время игры она будет задействована",
      locationImage:"представь что тебе надо нарисовать описание к этой локации, напиши тут запрос к нейросети Dall-e на английском что-бы она нарисовала её",
      locationPersons:[тут просто перечисли всех тех кто живет в этой локиции или по ире будет дольше всего находится]
    },
    {... точто так же все другие локации которые ты придумал}
  ]
}

Ответ обязательно должен быть в JSON формате, это важно! Кроме самомго JSON ничего больше писать не надо! Оттет должен начинаться с {
Все поля в ответе должны быть на русском (если в них не указано иного языка).
`;

const groupText = `
На основе этих знаний опиши каждую группу из списка за следующим шаблоном:
}
  groups:[
    {
      groupName:"название первой группы которую ты придумал",
      groupTitle:"краткое описание группы, кто в неё входит",
      groupDescription:"опиши как эта группа взаимодействует с другими группами",
      groupImage:"представь что тебе надо нарисовать эту группу, напиши тут запрос к нейросети Dall-e на английском что-бы она нарисовала её"
    },
    {... точто так же все другие группы которые ты придумал}
  ]
}

Ответ обязательно должен быть в JSON формате, это важно! Кроме самомго JSON ничего больше писать не надо! Оттет должен начинаться с {
Все поля в ответе должны быть на русском (если в них не указано иного языка).
`;

const personText = `
На основе этих знаний опиши из твоего списка следующих пересонажей: "Весемир", "Кейра Мец", "Золтан Хивай", "Шани", "Фрингилла Виго", "Лето из Гулета", "Эмгыр вар Эмрейс", "Эредин Бреак Глас".
Описание проводи за следующим шаблоном:
{
  persons:[
    {
      personName:"имя первого персонажа которого ты придумал",
      personTitle:"краткое описание персонажа известное всем",
      personDescription:"этот текст будет доступен только игроку получившим эту роль, тут опиши всё что игрок должен знать, в том числе секретную для других информацию",
      personSecrets:"этот текст будет доступен сожзателям игры в нем опиши каков твой замысел при создании этого персонажа, чего он должен достичь, и кем он на самом деле является если он притворяется кем-то",
      personImage:"представь что тебе надо нарисовать этого персонажа в его естественном окружении, напиши тут запрос к нейросети Dall-e на английском что-бы она нарисовала это",
      personLocation:"укажи название локации к котрой относится это персонаж (живёт там, или чаще всего бывает)",
      personGroups:[тут перечисли одну или несколько групп к которым относится этот персонаж],
      personRelations:[тут просто перечисли всех тех кто бует связан с этим персонажем общими сюжетами или другими взаимодействиями]
    },
    {... точто так же для всех других персонажей, которых ты придумал}
  ]
}

Ответ обязательно должен быть в JSON формате, это важно! Кроме самомго JSON ничего больше писать не надо! Оттет должен начинаться с {
Все поля в ответе должны быть на русском (если в них не указано иного языка).
`;

const stuffText = `
На основе этих знаний опиши каждый предмет из списка за следующим шаблоном:
{
  stuffs:[
    {
      stuffName:"название антуражного элемента который ты придумал",
      stuffTitle:"краткое описание этой вещи",
      stuffDescription:"опиши где она будет находится, локация, или персонажа, если она на нём",
      stuffImage:"представь что тебе надо нарисовать эту вещь, напиши тут запрос к нейросети Dall-e на английском что-бы она нарисовала её"
    },
    {... точто так же все другие вещи которые ты придумал}
  ]
}

Ответ обязательно должен быть в JSON формате, это важно! Кроме самомго JSON ничего больше писать не надо! Оттет должен начинаться с {
Все поля в ответе должны быть на русском (если в них не указано иного языка).
`;

const eventText = `
На основе этих знаний опиши каждое событие из списка за следующим шаблоном:
{
  events:[
    {
      eventName:"название события или квеста которое ты придумал",
      eventTitle:"краткое описание квеста или события",
      eventDescription:"описание доступно только мастерам, что именно детально будет происходить на этом событии или квесте, какие сюжетные повороты и когда оно должно начатся",
      eventImage:"представь что тебе надо нарисовать описание события, напиши тут запрос к нейросети Dall-e на английском что-бы она нарисовала это",
      eventPersons:[тут перечисли всех персонажей которые будут принимать участие в этом событии или квесте, или оставь пустой если это событие или квесто для кого угодно]
    },
    {... точто так же все другие которые ты придумал}
  ]
}

Ответ обязательно должен быть в JSON формате, это важно! Кроме самомго JSON ничего больше писать не надо! Оттет должен начинаться с {
Все поля в ответе должны быть на русском (если в них не указано иного языка).
`;

const TestPage: React.FC = () => {
  const { api } = useStores();
  const [text, setText] = useState(baseText + eventText);
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);
  const send = async () => {
    setLoading(true);
    const resultData = await api.getData('aitest', { method: 'POST' }, { text });
    if (typeof resultData === 'object') {
      const returnObject = await resultData.text();
      setResult(returnObject);
    }
    setLoading(false);
  };
  return (
    <Container style={{ paddingTop: 24 }}>
      <div className='shadow welcome-container mb-4' style={{ margin: 'auto' }}>
        <Row>
          <Col className='mb-3'>
            <Form.Control
              as='textarea'
              rows={5}
              id='masterInfo'
              size='lg'
              value={text || ''}
              onChange={(e) => setText(e.target.value)}
              style={{ height: '200px', lineHeight: '1.2rem', fontSize: 14 }}
            />
          </Col>
        </Row>
        <Row>
          <Col className='text-center'>
            {loading ? (
              <Spinner />
            ) : (
              <Button
                variant='success'
                className='col-12'
                size='lg'
                type='button'
                style={{ whiteSpace: 'nowrap', width: '20%' }}
                onClick={() => send()}
              >
                Send
              </Button>
            )}
          </Col>
        </Row>
      </div>
      {result ? (
        <div className='shadow welcome-container' style={{ margin: 'auto' }}>
          <p
            style={{ lineHeight: '1.2rem', fontSize: 14 }}
            dangerouslySetInnerHTML={{
              __html: result.replaceAll('\n', '<br/>'),
            }}
          />
        </div>
      ) : (
        ''
      )}
    </Container>
  );
};
export default TestPage;
//<img src={logo} style={{ height: 50, margin: '-20px 0 -12px 0' }} alt='' />
