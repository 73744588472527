import BaseStore from './BaseStore';
import { TUserRole } from './SessionStore';
import { TText } from './TextsStore';
import { TUser } from './UsersStore';

export enum ProjectStatuses {
  Concept = 'Concept',
  Planning = 'Planning',
  TeamAssembly = 'TeamAssembly',
  Anounce = 'Anounce',
  Writing = 'Writing',
  OpenForRequests = 'OpenForRequests',
  CloseForRequests = 'CloseForRequests',
  Production = 'Production',
  Event = 'Event',
  Feedback = 'Feedback',
}

export enum ProjectTypes {
  LARP = 'LARP',
  LORE = 'LORE',
}

export enum ProjectPayTypes {
  NEW = 'NEW',
  PAYED = 'PAYED',
  STOP = 'STOP',
  END = 'END',
  FREE = 'FREE',
}

export enum ProjectRelaions {
  Owner = 'owner',
  Manager = 'manager',
  Participant = 'participant',
  Subscriber = 'subscriber',
  Admin = 'admin',
}

export type TProjectSettings = {
  lang: string;
  openAiKey?: string;
  useAI?: boolean;
  imageAIAvailable?: boolean;
  useImageAI?: boolean;
  defaultImagePrompt?: string;
  imageStyle?: string;
  freQuenta?: boolean;
  inviteOnly?: boolean;
  gsh?: string;
  //[key: string]: string | number | boolean | undefined
};

export type TProjectLARP = {
  project_id?: number;
  project_name: string;
  project_title: string;
  project_description: string;
  project_status: string;
  project_images?: string[];
  project_settings: TProjectSettings | string;
  project_start: moment.Moment | string;
  project_end: moment.Moment | string;
  project_open: number;
  project_pricing: string;
  project_type?: string;
  project_pay_status?: ProjectPayTypes;
  user_id: number;
  owner?: string;
  img_id?: number;
  img_mime?: string;
  image?: string;
  user_role?: TUserRole[];
  texts?: TText[];
  user_person?: TUser[];
  user_request?: TUser[];
  can_free_pricing?: boolean;
  folowers?: number;
  status?: string;
  loaded?: boolean;
};

export type TProjectLORE = {
  project_id?: number;
  project_name: string;
  project_title: string;
  project_status: string;
  project_images?: string[];
  project_settings: TProjectSettings | string;
  project_pricing: string;
  project_type?: string;
  project_pay_status?: ProjectPayTypes;
  user_id: number;
  owner?: string;
  img_id?: number;
  img_mime?: string;
  image?: string;
  user_role?: TUserRole[];
  texts?: TText[];
  user_person?: TUser[];
  user_request?: TUser[];
  can_free_pricing?: boolean;
  folowers?: number;
  status?: string;
  loaded?: boolean;
};

export type TProject = TProjectLARP | TProjectLORE;
class ProjectsStore extends BaseStore {
  idKey = 'project_id';
  get data(): TProject[] {
    return this._data as TProject[];
  }
  settings(projectId: number): TProjectSettings {
    const settings = this.get(projectId).project_settings;
    if (typeof settings === 'string') {
      try {
        return JSON.parse(settings.replaceAll("'", '"'));
      } catch (e) {
        return {} as TProjectSettings;
      }
    }
    if (settings) return settings;
    return {} as TProjectSettings;
  }
}
export default ProjectsStore;
