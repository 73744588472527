import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'services/useStore';
import { Col, Modal, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LiqPayButton from 'components/Buttons/LiqPayButton';
import moment from 'moment';
import { ProjectPayTypes, TProject } from 'stores/ProjectsStore';

export interface ISubscribeDialog {
  projectId: number;
  show: boolean;
  callback?: () => void;
  hideCallback: () => void;
}

const SubscribeDialog: React.FC<ISubscribeDialog> = observer(
  ({ projectId, show, callback, hideCallback }) => {
    const { t } = useTranslation();
    const [amount, setAmount] = useState(0);
    const [pricingName, setPricingName] = useState('');
    const [firstPayDate, setFirstPayDate] = useState('');
    const [paymentDescription, setPaymentDescription] = useState('');
    const { api, projectStore, sessionStore } = useStores();
    useEffect(() => {
      if (!show || !projectId) return;
      const project: TProject = projectStore.get(projectId);
      const price = project.project_pricing;
      setPaymentDescription(t('billing.subscribeProjectDescription') + project.project_name);
      setPricingName(price);
      setAmount(api.settings.pricing[price as keyof typeof api.settings.pricing]);
      if (project.project_pay_status === ProjectPayTypes.NEW) {
        setFirstPayDate(moment().add(30, 'd').format('DD.MM.YY'));
      } else {
        setFirstPayDate(moment().format('DD.MM.YY'));
      }
    }, [projectId, show]);
    return (
      <Modal show={show} size='sm' key='editModal' onHide={hideCallback}>
        <Modal.Header closeButton>{t('billing.subscribe')}</Modal.Header>
        <Modal.Body>
          <Row className='justify-content-md-center'>
            <Col style={{ fontSize: 20 }}>
              {t('pricing.pricing')}: <b>{t('pricing.' + pricingName + '.name')}</b>
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col style={{ fontSize: 20 }}>
              {t('billing.amount')}: <b>{amount}</b>
              {t('billing.perMounce')}
            </Col>
          </Row>
          <Row className='mb-3 justify-content-md-center'>
            <Col style={{ fontSize: 20 }}>
              {t('billing.subscribeFirstPay')}: <b>{firstPayDate}</b>
            </Col>
          </Row>
          <Row className='justify-content-md-center text-muted'>
            <Col>{t('billing.chouseBillingSystem')}</Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col>
              <LiqPayButton
                id={`project_${projectId}_${sessionStore.session.user_id}`}
                amount={amount}
                description={paymentDescription}
                width={265}
              />
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }
);

export default SubscribeDialog;
